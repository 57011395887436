<template>
  <div class="flex flex-col justify-center items-center space-y-4 max-w-sm">
    <BaseIcon :icon="icon" class="text-primary text-6xl" outline />
    <div class="text-xl font-semibold">
      {{ title }}
    </div>
    <div>
      {{ desc }}
    </div>
  </div>
</template>
<script>
export default {
  name: "FeaturesCard",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
  },
};
</script>
