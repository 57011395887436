<template>
  <div class="w-full bottom-0 z-40 flex justify-center mt-24 mb-16">
    <div class="w-full max-w-6xl px-16">
      <div
        class="w-full flex flex-col md:flex-row gap-8 flex-wrap md:flex-nowrap md:justify-between"
      >
        <!-- social networks -->
        <div class="flex flex-col">
          <div class="font-bold text-xl mb-4 flex items-center gap-2">
            <img
              src="../assets/img/icon.png"
              alt="JukePhone logo"
              class="h-8 w-8"
            />
            <span>JukePhone</span>
          </div>
          <div class="text-sm mb-4">Suivez notre équipe !</div>

          <div class="flex gap-2">
            <BaseButton
              v-for="icon in icons"
              :key="icon.name"
              :href="icon.href"
              type="default"
              icon
              hyperlink
              @click="handleAction(icon.href)"
            >
              <BaseLogo :icon="icon.name" class="h-6 w-6" />
            </BaseButton>
          </div>
        </div>
        <!-- links -->
        <FooterItem
          v-for="card in cards"
          :key="card.title"
          :title="card.title"
          :items="card.items"
        />
      </div>
      <div
        class="flex justify-center items-center text-gray-5 dark:text-gray-3 text-sm mt-8"
      >
        &#169; {{ year }} JukePhone. Tous droits réservés
      </div>
    </div>
  </div>
</template>

<script>
import FooterItem from "@/layout/FooterItem.vue";
import { mapActions } from "vuex";

export default {
  name: "Footer",
  components: {
    FooterItem,
  },
  data() {
    return {
      cards: [
        {
          title: "Informations",
          items: [
            { title: "Contactez-nous", href: "/contact" },
            { title: "CGU", href: "/terms-of-use" },
          ],
        },
        {
          title: "Nous contacter",
          items: [
            { title: "Utilisateurs", href: "/contact?role=client" },
            { title: "Partenaires", href: "/contact?role=partner" },
          ],
        },
      ],
      icons: [
        { name: "fb", href: "https://www.facebook.com/jukephoneapp" },
        { name: "ig", href: "https://www.instagram.com/jukephone/" },
        { name: "in", href: "https://www.linkedin.com/showcase/jukephone/" },
      ],
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    handleAction(href) {
      this.sendLog({
        route: this.$route.fullPath,
        type: "ACTION",
        meta: { location: "Footer", href },
      });
    },
    ...mapActions(["sendLog"]),
  },
};
</script>
