import { v4 } from "uuid";
import store from "../store/index.js";

const session = (to, from, next) => {
  if (store.state.session === null) {
    store.commit("setSession", v4());
  }

  store.dispatch("sendLog", {
    route: to.fullPath,
    type: "NAVIGATION",
    meta: { to: to.fullPath, from: from.fullPath },
  });

  next();
};

export default session;
