import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  plugins: [
    createPersistedState({
      key: "jukephone-landing-page",
    }),
  ],
  state() {
    return {
      theme: null,
      session: null,
    };
  },
  mutations: {
    setTheme(state, payload) {
      state.theme = payload;

      if (payload === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
    setSession(state, payload) {
      state.session = payload;
    },
  },
  actions: {
    setTheme({ commit }, payload) {
      commit("setTheme", payload);
    },
    initTheme({ dispatch, getters }) {
      if (
        getters.theme === "dark" ||
        (!getters.theme &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        dispatch("setTheme", "dark");
      } else {
        dispatch("setTheme", "light");
      }
    },
    async sendContact(_, payload) {
      try {
        await this.$services.contacts.send({
          payload,
          meta: { version: "beta" },
        });
      } catch (err) {
        throw err;
      }
    },
    async getNews() {
      try {
        const { data } = await this.$services.news.get();
        return data;
      } catch (err) {
        throw err;
      }
    },
    async sendLog({ state }, payload) {
      try {
        if (payload.type === "NAVIGATION") {
          const ip = await this.$services.ip.get();
          payload.meta.ip = ip;
        }

        payload.session = state.session;
        await this.$services.logs.send(payload);
      } catch (err) {
        throw err;
      }
    },
  },
  getters: {
    theme(state) {
      return state.theme;
    },
    isDark(state) {
      return state.theme === "dark";
    },
  },
});

export default store;
