<template>
  <master-layout>
    <Conditions />
  </master-layout>
</template>

<script>
import Conditions from "@/components/Terms/Conditions";
export default {
  name: "Terms",
  components: { Conditions },
};
</script>
