<template>
  <div class="flex flex-col">
    <div class="font-bold text-xl mb-4">{{ title }}</div>

    <div class="flex flex-col">
      <div v-for="item in items" :key="item.title" class="mb-2">
        <router-link :to="item.href" class="hover:text-primary">{{
          item.title
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterItem",
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
};
</script>
