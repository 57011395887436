<template>
  <BaseSection id="about">
    <div class="flex flex-col-reverse md:flex-row gap-16" v-scroll-reveal>
      <div class="flex justify-center items-center w-full md:w-96">
        <img class="w-96 md:w-full rounded-lg" src="@/assets/img/about.jpg" />
      </div>
      <div class="flex flex-col my-auto">
        <BaseSubTitle>À propos</BaseSubTitle>
        <BaseTitle>En savoir plus sur JukePhone</BaseTitle>
        <div class="mb-4 -mt-4">
          <div>Découvrez notre équipe et notre technologie</div>
        </div>
        <div>
          <BaseButton to="/about">En savoir plus</BaseButton>
        </div>
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "About",
};
</script>
