<template>
  <BaseSection class="flex-col" id="cgu">
    <div class="flex flex-col justify-center">
      <BaseTitle position="top">Conditions Générales d'Utilisation </BaseTitle>
      <BaseSubTitle position="top">MAJ 16/08/2022</BaseSubTitle>
    </div>
    <div class="space-y-4">
      <ConditionsArticle
        v-for="(article, index) in articles"
        prefix="Article"
        :key="index"
        :title="article.title"
        :body="article.body"
        :index="index"
      />
    </div>
  </BaseSection>
</template>

<script>
import ConditionsArticle from "@/components/Terms/ConditionsArticle";

export default {
  name: "Conditions",
  components: {
    ConditionsArticle,
  },
  data() {
    return {
      articles: [
        {
          title: "Préambule",
          body: `Les présentes Conditions Générales d’Utilisation (ci-après « CGU ») s’appliquent à l’utilisation de la plateforme web ou de l'application mobile « Jukephone » » (ci-après respectivement « la Plateforme web » et "l'Application") , ainsi qu’à l’ensemble des Services qui sont proposés par la société JukePhone (ci-après « la Société »).<br><br>
          Le développement de la Plateforme web et de l'Application est assurée par contact@jukephone.fr (ci-après « l’Éditeur »).<br><br>
          En accédant à la Plateforme web ou à l'Application, vous acceptez sans réserve l’intégralité des présentes CGU et vous engagez à respecter les obligations à votre charge. Dans le cas où vous n’accepteriez pas les CGU ou auriez des réserves, merci de ne pas utiliser ni la Plateforme web ni l'Application. En cas de manquement au respect des CGU, l’Éditeur se réserve le droit de suspendre de manière temporaire ou définitive l’accès à la Plateforme web et à l'Application. La Plateforme Web et l'Application se réserve tout droit de modifier les clauses de ces CGU à tout moment et sans justification.<br><br>
          On entend par «Utilisateur » dans les CGU toute personne qui accède à la Plateforme web et/ou à l'Application. Tout Utilisateur doit être âgé d’au moins 18 ans ou 13 ans en ayant l’accord d’un parent ou d’un tuteur.<br><br>
          L’utilisation de la Plateforme web ou de l'Application est réservée à un usage strictement personnel et privé dans le cercle familial ou amical. Ainsi l’utilisation en dehors de ce cadre et notamment à des fins commerciales ou de sonorisation de lieux publics est expressément interdite.<br><br>
          `,
        },
        {
          title: "Description du service",
          body: `La Plateforme web et l'Application permettent de diffuser de la musique par internet (ci-après « streaming » à travers la plateforme Spotify (se référer à leurs CGU) . Elles donnent ainsi accès aux mêmes titres que ceux présents sur cette plateforme si vous y possédez un compte. Elles ne permettent pas le téléchargement permanent ou temporaire des musiques écoutées et nécessitent par conséquent une connexion internet.<br><br>
          La Plateforme web est accessible gratuitement depuis le site jukephone.fr et l'Application est accessible gratuitement depuis la plateforme « Google Play Store ». Les frais supportés par l’Utilisateur pour y accéder (connexion internet, matériel informatique, etc.) ne sont pas à la charge de la Société.<br><br>
          La Plateforme web et l’Application vous permettent la création de salons virtuels temporaires (ci-après « Salons »)  qui donnent la possibilité aux utilisateurs de rejoindre ceux-ci afin de créer une playlist collaborative et d’influencer l’ordre de passage des chansons, grâce à un système de vote et d’influence.<br><br>
          Ces fonctionnalités peuvent être enrichies ou modifiées à tout moment par l’Éditeur sans que sa responsabilité ne puisse être engagée à ce titre. <br><br>
          Tout Utilisateur voulant créer un Salon (ci-après « Hôte ») doit se connecter avec son compte Spotify et accepter le service et les conditions générales d’utilisations de Spotify. <br><br>
          Tout Utilisateur voulant rejoindre un Salon (ci-après « Invité ») est invité à se connecter aux services Spotify.<br><br>
          `,
        },
        {
          title: `Responsabilité des Utilisateurs`,
          body: `Chaque Utilisateur a la possibilité d’exprimer un pseudonyme ou de laisser des messages, informations et/ou commentaires sur la Plateforme web et/ou l'Application. L’Utilisateur est alors seul responsable du contenu ainsi publié par ses soins. L’Utilisateurs s’engage expressément, sous peine de voir sa responsabilité pénale ou civile engagée, à ce que les messages qu’il met en ligne ne soient pas de nature à, sans que cette liste ne présente un caractère exhaustif, notamment :
            <ul style="list-style-type:disc; padding-left:48px">
            <li>constituer une violation des droits de propriété intellectuelle de tiers ;</li>
            <li>faire l’apologie de crimes ou contenir des messages illégaux, menaçants, ou à caractère pédophile, pornographique, diffamatoire, obscène, haineux, raciste, antisémite, xénophobe, révisionniste ou contraire à l’ordre public ou aux bonnes mœurs ;</li>
            <li>porter atteinte à la vie privée ou au respect de la dignité des tiers ;</li>
            <li>inciter à la violence, au fanatisme, au crime, au suicide, à la haine en raison de la religion, de la race, du sexe, de l’orientation sexuelle, de l’ethnie ;</li>
            <li>harceler les autres Utilisateurs ;</li>
            <li>favoriser ou encourager toute activité ou entreprise criminelle ;</li>
            <li>solliciter et/ou communiquer des mots de passe et/ou des informations personnelles à des fins commerciales ou illégales ;</li>
            <li>transmettre des chaînes d’e-mails, de mailings massifs non sollicités, de messages instantanés, de messages publicitaires non souhaités ou de courriers non sollicités ;</li>
            <li>comporter des annonces publicitaires et/ou sollicitations afin de proposer des produits et/ou services à la commercialisation par l’intermédiaire du Site ;</li>
            <li>contenir des adresses ou des liens hypertextes renvoyant vers des sites extérieurs dont le contenu est contraire aux lois et règlements en vigueur, qui portent atteinte aux droits des tiers ou qui seraient contraires aux présentes conditions;</li>
            </ul>
            <div style="padding-left:28px">···</div><br>
          L’Utilisateur s’engage à ne pas recourir à l’utilisation automatique de systèmes informatiques tels que des scripts en vue d’ajouter des Utilisateurs à son Profil et/ou d’envoyer des commentaires ou des messages.<br><br>
          La Plateforme web et l'Application se réservent, dans l’hypothèse où l’Utilisateur se rendrait responsable d’une infraction à la législation en vigueur ou d’une atteinte aux droits des tiers, le droit de fournir, à la demande de toute autorité légitime (juridiction, autorité administratives, services de police), tous renseignements permettant ou facilitant l’identification de l’Utilisateur contrevenant.<br><br>
          `,
        },
        {
          title: `Responsabilité de l’Éditeur `,
          body: `Tout dysfonctionnement du serveur ou du réseau ne peut engager la responsabilité de l’Éditeur.<br><br>
          De même, la responsabilité de la Plateforme web et/ou de l’Application ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.<br><br>
          La Plateforme web et l’Application s’engagent à mettre en œuvre tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données. Toutefois, elles n’apportent pas une garantie de sécurité totale.<br><br>
          L’Éditeur se réserve la faculté d’une non-garantie de la fiabilité des sources, bien que les informations diffusées sur la Plateforme web et sur l'Application soient réputées fiables.<br><br>
          `,
        },
        {
          title: `Traitement des données personnelles`,
          body: `Les données personnelles de l’Utilisateur autre que son adresse mail ne sont pas stockées, et ne sont utilisées que pour le bon fonctionnement de la Plateforme web et/ou l’Application pendant la durée de l’utilisation. Aucune donnée obligatoire autre que l'adresse mail n’est demandée. L'adresse mail seule est stockée sur un serveur européen et n'est pas utilisée à des fins commerciales : il n'existe aucune communication qui puisse permettre à un quelconque service tiers d'accéder à l'adresse mail de l'Utilisateur.<br><br>
          Les données transmises par Spotify pendant le temps d’ouverture d’un Salon ne sont pas stockées, ne sont pas utilisées à des fins commerciales ni transférées à des tiers. <br><br>
          L’Éditeur s’engage du bon respect de ces pratiques.<br><br>
          `,
        },
      ],
    };
  },
};
</script>
