<template>
  <div class="flex flex-col md:flex-row">
    <div
      class="whitespace-pre-line md:text-xl text-lg pr-3 font-semibold md:min-w-[192px] mb-4"
    >
      {{ computedTitle }}
    </div>
    <slot></slot>
    <div v-html="body" class="text-justify"></div>
  </div>
</template>

<script>
export default {
  name: "ConditionsArticle",
  props: {
    index: {
      type: Number,
    },
    title: {
      type: String,
    },
    body: {
      type: String,
    },
    prefix: {
      type: String,
    },
  },
  computed: {
    computedTitle() {
      return this.prefix
        ? `${this.prefix} ${this.index + 1} : ${this.title}`
        : this.title;
    },
  },
};
</script>
