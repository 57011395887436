import { createApp } from "vue";
import vClickOutside from "v-click-outside";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import services from "./services";

import "@/assets/css/tailwind.css";

import { initToast } from "./config/toast";
import { initScrollReveal } from "./config/scroll-reveal";
import { initDesignSystem } from "./config/design-system";

const app = createApp(App).use(router);

app.use(vClickOutside);

store.$services = services;
app.use(store);
store.dispatch("initTheme");

const ComponentContext = require.context("/", true, /\.vue$/i, "lazy");
initDesignSystem(app, ComponentContext);
initToast(app);
initScrollReveal(app);

router.isReady().then(() => {
  app.mount("#app");
});
