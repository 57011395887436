<template>
  <div
    class="w-full w-max-[100vw] bg-body dark:bg-body-dark text-gray-9 dark:text-gray-1 flex flex-wrap flex-col items-center overflow-x-hidden text-base"
  >
    <NavBar :scroll-header="scrollHeader" />
    <div
      class="w-full max-w-6xl min-h-[calc(100vh-416px)] flex items-center flex-col"
    >
      <slot></slot>
    </div>
    <ScrollButton :scroll-top="scrollTop" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Footer from "./Footer";
import ScrollButton from "@/layout/ScrollButton.vue";
import debounce from "../utils/debounce.js";
import { mapActions } from "vuex";

export default {
  name: "MasterLayout",

  components: {
    NavBar,
    Footer,
    ScrollButton,
  },
  data() {
    return { scrollHeader: false, scrollTop: false, debounceHandler: null };
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
    this.debounceHandler = debounce(this.logEvent, 1000);
  },
  methods: {
    handleScroll() {
      this.scrollHeader = window.scrollY >= 200;
      this.scrollTop = window.scrollY >= 560;
      this.debounceHandler();
    },
    logEvent() {
      this.sendLog({
        route: this.$route.fullPath,
        type: "BEHAVIOR",
        meta: { scroll: window.scrollY },
      });
    },
    ...mapActions(["sendLog"]),
  },
};
</script>
