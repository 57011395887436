<template>
  <master-layout>
    <Team />
  </master-layout>
</template>

<script>
import Team from "@/components/About/Team";
export default {
  name: "About",
  components: { Team },
};
</script>
