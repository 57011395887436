import axios from "axios";

import { ContactService } from "./contact";
import { NewsService } from "./news";
import { LogService } from "./log";

const client = axios.create({
  baseURL:
    location.hostname === "localhost"
      ? "http://localhost:4001/api"
      : "https://jukephone-server.alwaysdata.net/api",
  headers: { "Access-Control-Allow-Origin": "*" },
});

const ip = {
  get: async () => {
    const { data } = await axios.get("https://api.ipify.org");
    return data;
  },
};

const services = {
  contacts: new ContactService(client),
  news: new NewsService(client),
  logs: new LogService(client),
  ip,
};

export default services;
