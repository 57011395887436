// import ToastPlugin from "vue-toast-notification";
// Import one of the available themes
// import "vue-toast-notification/dist/theme-default.css";
// import "vue-toast-notification/dist/theme-sugar.css";
// import "./theme-jukephone.css";

export const initToast = (app) => {
  // app.use(ToastPlugin, { position: "top" });
  return app;
};
