<template>
  <BaseSection id="home" class="full-landing -mt-16">
    <div
      class="flex flex-col items-center justify-center lg:flex-row gap-8"
      v-scroll-reveal
    >
      <div class="space-y-4">
        <div class="text-primary text-4xl md:text-6xl font-semibold">
          JukePhone
        </div>
        <div class="text-xl font-semibold">
          Changeons notre façon d'écouter de la musique ensemble.
        </div>
        <div>
          <BaseButton href="https://app.jukephone.fr/" @click="handleAction">
            Accéder à la plateforme
          </BaseButton>
        </div>
      </div>
      <div class="w-full md:w-4/5 flex items-center">
        <img src="@/assets/img/home.png" />
      </div>
    </div>
  </BaseSection>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Home",
  methods: {
    handleAction() {
      this.sendLog({
        route: this.$route.fullPath,
        type: "ACTION",
        meta: { location: "Home", href: "https://app.jukephone.fr/" },
      });
    },
    ...mapActions(["sendLog"]),
  },
};
</script>

<style scoped>
.full-landing {
  height: calc(100vh - 64px);
}
</style>
