<template>
  <BaseSection id="features" class="mb-16">
    <div class="w-full flex flex-col text-center" v-scroll-reveal>
      <BaseSubTitle>Concept</BaseSubTitle>

      <BaseTitle>Nos Fonctionnalités</BaseTitle>

      <div class="flex justify-center gap-8 flex-wrap lg:flex-nowrap">
        <FeaturesCard
          v-for="item in cards"
          :key="item.icon"
          :title="item.title"
          :desc="item.desc"
          :icon="item.icon"
        />
      </div>
    </div>
  </BaseSection>
</template>
<script>
import FeaturesCard from "@/components/Landing/FeaturesCard.vue";

export default {
  name: "Features",
  components: {
    FeaturesCard,
  },
  data() {
    return {
      cards: [
        {
          title: "Créez une playlist partagée",
          desc: "Ouvrez un salon à tous les membres de votre réunion et accédez ensemble à la playlist partagée",
          icon: "share",
        },
        {
          title: "Ajoutez vos chansons préférées",
          desc: "Accédez à une large bibliothèque musicale et ajoutez selon vos goûts la musique que vous aimez",
          icon: "music_note",
        },
        {
          title: "Interagissez avec la playlist",
          desc: "Votez pour classer les musiques dans la playlist et exprimez-vous sur les chansons proposées",
          icon: "favorite",
        },
      ],
    };
  },
};
</script>
