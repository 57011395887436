<template>
  <div
    class="px-4 md:transition-colors font-medium hover:text-primary cursor-pointer rounded hover:bg-primary hover:bg-opacity-10"
    :class="[active ? 'active' : '']"
  >
    <router-link :to="href">{{ name }}</router-link>
  </div>
</template>
<script>
export default {
  name: "NavItem",
  props: {
    href: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.active {
  color: theme("colors.primary.DEFAULT") !important;
}
</style>
