<template>
  <div class="w-full">
    <!-- FIXED -->
    <div
      class="w-full h-16 fixed top-0 z-40 flex justify-center bg-body dark:bg-body-dark"
      :class="[scrollHeader ? 'shadow' : '']"
    >
      <div
        class="w-full max-w-6xl h-16 px-4 md:px-16 flex items-center justify-between"
      >
        <!-- left navbar -->
        <div class="flex items-center gap-4">
          <router-link
            to="/"
            class="flex items-center font-semibold hover:text-primary gap-1 transition-colors"
          >
            <img
              src="../assets/img/icon.png"
              alt="JukePhone logo"
              class="h-8 w-8"
            />
            <div>JukePhone</div>
          </router-link>
          <div class="hidden md:flex items-center">
            <NavItem
              v-for="item in menu"
              :key="item.href"
              :href="item.href"
              :name="item.name"
              :active="item.href === activeNav"
            />
          </div>
        </div>

        <!-- right navbar -->
        <div class="hidden md:flex items-center gap-4">
          <BaseButton
            href="https://app.jukephone.fr/login"
            @click="handleAction"
          >
            Se connecter
          </BaseButton>

          <ThemeSwitch />
        </div>
        <!-- mobile nav -->
        <div
          class="flex md:hidden items-center cursor-pointer"
          @click="nav = !nav"
        >
          <BaseIcon v-if="nav" icon="close" size="20px" />
          <BaseIcon v-else icon="menu" size="20px" />
        </div>
      </div>
    </div>
    <!-- PLACEHOLDER -->
    <div class="w-full h-16"></div>
    <!-- mobile dropdown -->
    <div
      class="fixed z-50 w-full top-16 overflow-hidden transition-height duration-300 rounded-b-xl bg-body dark:bg-body-dark shadow"
      :class="[nav ? 'max-h-full' : 'max-h-0']"
      v-click-outside="closeNav"
    >
      <div class="flex flex-col items-center w-full py-4 z-50">
        <NavItem
          v-for="item in menu"
          :key="item.href"
          :href="item.href"
          :name="item.name"
          :active="item.href === activeNav"
          @click.native="closeNav"
        />
        <ThemeSwitch />
      </div>
    </div>
  </div>
</template>

<script>
import NavItem from "@/layout/NavItem";
import ThemeSwitch from "@/layout/ThemeSwitch";
import { mapActions } from "vuex";

export default {
  name: "NavBar",
  components: {
    NavItem,
    ThemeSwitch,
  },
  props: {
    scrollHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nav: false,
    };
  },
  computed: {
    activeNav() {
      return this.$route.path;
    },
  },
  methods: {
    closeNav() {
      this.nav = false;
    },
    handleAction() {
      this.sendLog({
        route: this.$route.fullPath,
        type: "ACTION",
        meta: { location: "NavBar", href: "https://app.jukephone.fr/login" },
      });
    },
    ...mapActions(["sendLog"]),
  },
  data() {
    return {
      dropDownOpen: false,
      menu: [
        { href: "/about", name: "À propos" },
        { href: "/contact", name: "Contact" },
      ],
    };
  },
};
</script>
