<template>
  <master-layout>
    <ContactForm :role="role" />
  </master-layout>
</template>

<script>
import ContactForm from "@/components/Contact/ContactForm";
export default {
  name: "Contact",
  components: {
    ContactForm,
  },
  data() {
    return {
      role: this.$route.query.role,
    };
  },
  watch: {
    $route() {
      this.role = this.$route.query.role;
    },
  },
};
</script>
