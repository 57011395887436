var sr = require("scrollreveal").default();

export const scrollReveal = {
  mounted(el, binding) {
    const options = binding.value || {};
    // el.childNodes
    sr.reveal(
      el,
      {
        origin: "top",
        distance: "0px",
        duration: 2000,
        delay: 100,
        mobile: false,
        reset: false,
        ...options,
      },
      1000
    );
  },
};

export const initScrollReveal = (app) => {
  app.directive("scroll-reveal", scrollReveal);
  return app;
};
