import { createRouter, createWebHistory } from "vue-router";
import scrollBehavior from "./scrollBehavior";
import Landing from "../pages/Landing.vue";
import Terms from "../pages/Terms.vue";
import About from "@/pages/About.vue";
import Contact from "@/pages/Contact.vue";
import session from "../middlewares/session";

const routes = [
  {
    path: "/",
    component: Landing,
    name: "index",
  },
  {
    path: "/terms-of-use",
    component: Terms,
    name: "terms-of-use",
  },
  {
    path: "/about",
    component: About,
    name: "about",
  },
  {
    path: "/contact",
    component: Contact,
    name: "contact",
  },
  //unknown route
  {
    path: "/:path(.*)*",
    redirect: "/",
    name: "404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: scrollBehavior,
});

router.beforeEach(session);

export default router;
