<template>
  <BaseSection id="about-page">
    <div class="flex flex-col w-full">
      <BaseTitle position="top">A propos</BaseTitle>
      <div class="mt-4 mb-12 text-2xl text-primary">Découvrez notre équipe</div>
      <div
        class="flex flex-col md:flex-row items-center md:justify-around gap-4"
      >
        <Profile
          name="Charleene Xu"
          description="CEO"
          img="charleene.jpeg"
          url="https://www.linkedin.com/in/charleene-xu"
        />
        <Profile
          name="Quentin Juarez"
          description="CTO"
          img="quentin.jpeg"
          url="https://www.linkedin.com/in/quentin-juarez"
        />
      </div>

      <ConditionsArticle
        class="!flex-col mt-4"
        :title="story.title"
        :body="story.body"
      >
      </ConditionsArticle>

      <div class="mt-16 text-2xl text-primary">Notre accompagnement</div>
      <ConditionsArticle
        class="!flex-col mt-4"
        :title="school.title"
        :body="school.body"
      >
        <div
          class="flex items-center flex-col md:flex-row justify-center py-8 w-full gap-8"
        >
          <BaseImage
            height="100px"
            url="https://www.ensea.fr/"
            img="logo-ENSEA.png"
          />
          <BaseImage
            height="105px"
            url="https://dauphine.psl.eu/"
            img="logo-dauphine.png"
          />
          <BaseImage
            class="mb-4"
            height="80px"
            url="https://cy-entreprendre.fr/"
            img="logo-CY-entreprendre.png"
          />
        </div>
      </ConditionsArticle>

      <ConditionsArticle
        class="!flex-col mt-12"
        :title="incubators.title"
        :body="incubators.body"
      >
        <div
          class="flex items-center flex-col md:flex-row justify-left pb-8 pt-2 w-full gap-12"
        >
          <BaseImage
            height="80px"
            url="https://www.linkedin.com/company/wenovia/"
            img="logo-wenovia.jpeg"
          />
          <BaseImage
            height="60px"
            url="https://incubator.dauphine-psl.com/"
            img="logo-incubateur-dauphine.png"
          />
        </div>
      </ConditionsArticle>

      <div v-if="TODO" class="mt-16 text-2xl text-primary">
        Notre technologie
      </div>
      <ConditionsArticle
        v-if="TODO"
        class="!flex-col mt-12"
        title="TODO"
        body="TODO"
      >
      </ConditionsArticle>
      <div v-if="!!news.length">
        <div class="mt-16 text-2xl text-primary">Actualités</div>
        <div class="flex flex-col md:flex-row flex-wrap gap-8 mt-8">
          <BaseCard v-for="item in news" :key="item._id" v-bind="item" />
        </div>
      </div>
    </div>
  </BaseSection>
</template>

<script>
import Profile from "@/components/About/Profile";
import ConditionsArticle from "@/components/Terms/ConditionsArticle";
import { mapActions } from "vuex";

export default {
  name: "Team",
  components: {
    Profile,
    ConditionsArticle,
  },
  async mounted() {
    this.news = [
      {
        action: {
          text: "Écouter",
          url: "https://pepitesmti.lepodcast.fr/ep-point-3-algo-rythme-charleene-xu-et-jukephone",
        },
        _id: "6304d8a74dd7d1cc31f82e97",
        title: "ALGO RYTHME - PEPITE",
        desc: "<p><strong>Le podcast de l'innovation et de la technologie</strong></p><p><br></p><p><span style=\"background-color: rgb(253, 253, 253); color: rgb(51, 51, 51);\">Pour son troisième épisode, Pépite rencontre Charleene, étudiante du Master de Management et de l'Innovation, pour parler de sa startup JukePhone, incubée à Paris Dauphine et lancée avec Quentin Juarez. Elle nous partage entre deux notes son expérience entrepreneuriale étudiante et sa passion pour la musique. Bonne écoute 🎹🎶</span></p>",
        date: "25/09/22",
        cover:
          "https://political-sword-9c7.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F6c6be05c-87c2-400c-b25b-1ccf6326555c%2Fmedium_6bf69d753531ba33539e0080624a127a7e95fab2.jpeg?table=block&id=aed208f9-6f65-47e0-88a3-a37e585a5c2e&spaceId=98e916f0-f036-4ad1-a579-fae30efa4a35&width=1020&userId=&cache=v2",
        published: true,
        createdAt: "2022-08-23T13:39:51.666Z",
        updatedAt: "2023-01-06T21:55:48.861Z",
        __v: 0,
      },
      {
        action: {
          text: "Écouter",
          url: "https://soundcloud.com/user-616691784/sets/les-rencontres-du-numeric-lab",
        },
        _id: "63053961843b63a86531c9f2",
        title: "Être étudiant et entrepreneur - Rencontre du Numeric Lab",
        desc: '<p>De plus en plus d\'étudiants et jeunes diplômés ont un projet de création d\'entreprise pendant ou après leurs études, mais méconnaissent les réseaux et les dispositifs existants. JukePhone a participé à cette table ronde, qui propose un éclairage et des outils pour sensibiliser et&nbsp;orienter au mieux les étudiants à la culture entrepreneuriale.</p><p><br></p><p>En partenariat avec&nbsp;<a style="color: rgb(0, 0, 0);" rel="noopener noreferrer" href="https://www.agglo-plainevallee.fr/" target="_blank">Plaine Vallée Agglomération</a></p>',
        date: "28/10/21",
        published: true,
        cover:
          "https://www.cda95.fr/sites/default/files/styles/img__cc_main_is1620x1080/public/media/images/rencontre_0.jpg?itok=Bm1chWys",
        createdAt: "2022-08-23T20:32:33.542Z",
        updatedAt: "2023-01-06T21:54:47.562Z",
        __v: 0,
      },
    ]; //await this.getNews();
  },
  data() {
    return {
      news: [],
      story: {
        title: "Un projet né en école d'ingénieur",
        body: `JukePhone est avant tout un projet né de notre expérience en école
          d’ingénieur, à l'<a
          href="https://www.ensea.fr/"
          target="_blank"
          class="underline hover:text-primary"
          >ENSEA</a>. Nous étions beaucoup d'étudiants à ne pas
          pleinement pouvoir apprécier nos soirées parce qu'on ne se retrouvait
          pas dans les musiques proposées.<br /><br />Evidemment on avait envie
          d’écouter des musiques que tout le monde aime, les classiques Démons
          de Minuit et Lacs du Connemara, mais on avait aussi envie d'écouter
          des musiques qui nous ressemblaient plus, de découvrir de nouveaux
          titres, et pas forcément de grands artistes. On avait envie de Blues,
          de Rap, de Pop, de Hard Rock et encore. <br /><br />
          C’est pour ça qu’on a créé JukePhone, pour promouvoir la diversité des
          goûts, le partage et offrir l’option du choix à chacun !
          `,
      },
      school: {
        title: "École hôte et partenariats",
        body: `
        Notre idée est née de la rencontre des co-fondateurs à l'<a
        href="https://www.ensea.fr/"
        target="_blank"
        class="underline hover:text-primary"
        >ENSEA</a>, École Nationale de l'Electronique et de ses Applications. Notre école
        d'ingénieur nous a permis de nous épanouir, en offrant un cadre favorable aux
        initiatives liées à l'entrepreneuriat. Avec l'option Entrepreneuriat, nous avons été
        accompagnés plus de 4h par semaine par un professeur encadrant sur un semestre, approfondissant à la fois des notions classiques de
        management enseignées dans le parcours de formation initiale et également en
        appuyant nos compétences techniques, en particulier sur tout le volet
        informatique. En collaboration étroite avec le <a
        href="https://cy-entreprendre.fr/"
        target="_blank"
        class="underline hover:text-primary"
        >PEPITE CY Entreprendre</a>, cette option a également fédéré beaucoup d'étudiants-entrepreneurs,
        qui ont pu challenger notre solution, favoriser notre prise de recul sur le projet, et nous permettre
        de mener une réflexion profonde sur la musique et notre consommation de celle-ci.
        <br /><br />
        Grâce aux précieux conseils de nos collègues ensearques, au support de
        l’administration et surtout à l’aide apportée par nos professeurs, plus
        particulièrement Christophe Barès et Sylvain Reynal, nous avons pu bénéficier
        de tout le support indispensable à notre projet. Dans le cadre de sa réforme
        Beyond Engineering, l'école propose également des cursus pluridisciplinaires,
        notamment des doubles diplômes dans le domaine du management et de l'entrepreneuriat,
        comme avec l'<a
        href="https://dauphine.psl.eu/"
        target="_blank"
        class="underline hover:text-primary"
        >Université Paris Dauphine-PSL</a>.
          `,
      },
      incubators: {
        title: "Accélération et incubation",
        body: `
        En 2021, avec l'accompagnement du <a
        href="https://cy-entreprendre.fr/"
        target="_blank"
        class="underline hover:text-primary"
        >PEPITE CY Entreprendre</a>, JukePhone a été accéléré pendant trois mois
        par le programme Wenovia, avant d'intégrer en 2022 l'incubateur Paris Dauphine
        sur le <a
        href="https://incubator.dauphine-psl.com/nos-programmes/pour-les-etudiants/"
        target="_blank"
        class="underline hover:text-primary"
        >programme D-Start</a>.
          `,
      },
    };
  },
  methods: {
    ...mapActions(["getNews"]),
  },
};
</script>
