export default (to, from, savedPosition) => {
  let position = {};
  if (to.hash) {
    position = { el: to.hash, behavior: "smooth" };
  } else {
    position = { top: 0, left: 0 };
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(position);
    }, 0);
  });
};
