<template>
  <button
    class="flex flex-col items-center cursor-pointer group"
    @click="redirectURL()"
  >
    <!-- IMAGE   -->
    <div
      class="flex justify-center relative md:min-w-[12px] md:min-h-[12px] w-52 md:w-60 group-hover:scale-105 transition-all"
    >
      <div
        class="flex gap-1 absolute ml-5 top-[calc(50%-16px)] opacity-0 group-hover:opacity-100 content-[attr(text)] w-52 h-52 transition-opacity"
      >
        <BaseIcon icon="launch" class="text-base" />
        <span class="text-sm">Voir le profil sur LinkedIn</span>
      </div>
      <img
        class="w-full rounded-full group-hover:opacity-30 transition-opacity"
        :src="require(`@/assets/img/${img}`)"
      />
    </div>
    <!-- IMAGE DESCRIPTION -->
    <div class="pt-3 md:m-2 text-center text-sm font-semibold">{{ name }}</div>
    <div class="mb-6 text-center text-xs">{{ description }}</div>
  </button>
</template>

<script>
export default {
  name: "Profile",
  props: {
    url: {
      type: String,
    },
    img: {
      type: String,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  methods: {
    redirectURL() {
      window.open(this.url, "_blank");
    },
  },
};
</script>
