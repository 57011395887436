<template>
  <BaseIcon
    v-if="theme"
    @click="toggleTheme"
    :icon="icon"
    class="w-6 h-6 flex items-center justify-center rounded hover:text-primary hover:bg-primary/10 transition-colors text-base"
    outline
    button
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ThemeSwitch",
  computed: {
    icon() {
      return this.isDark ? "light_mode" : "dark_mode";
    },
    ...mapGetters(["isDark", "theme"]),
  },
  methods: {
    toggleTheme() {
      return this.isDark ? this.setTheme("light") : this.setTheme("dark");
    },
    ...mapActions(["setTheme"]),
  },
};
</script>
