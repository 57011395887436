import { v4 } from "uuid";

export const generateKey = (elementType) => `${elementType}-${v4()}`;

export const validators = {
  required: (text) => {
    return !!text.length;
  },
  email: (text) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(text);
  },
  default: () => true,
};

const devForm = {
  name: "Quentin",
  email: "quentin.juarez@hotmail.fr",
  message: "On " + new Date(),
};
export const getValue = (path) => {
  return location.hostname === "localhost" ? devForm[path] : "";
};
