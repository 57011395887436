import { defineAsyncComponent } from "vue";
import MasterLayout from "@/layout/MasterLayout.vue";

export const initDesignSystem = (app, context) => {
  context.keys().forEach((componentFilePath) => {
    if (componentFilePath.includes("components/_DesignSystem")) {
      const componentName = componentFilePath.split("/").pop().split(".")[0];

      const asyncComponent = defineAsyncComponent(
        () =>
          new Promise((resolve) => {
            resolve(context(componentFilePath));
          })
      );

      app.component("Base" + componentName, asyncComponent);
    }
  });

  app.component("master-layout", MasterLayout);
};
