<template>
  <BaseSection id="contact-from" class="flex flex-col md:flex-row md:gap-16">
    <div>
      <BaseSubTitle>Formulaire</BaseSubTitle>
      <BaseTitle>Nous contacter</BaseTitle>
    </div>
    <div class="bg-gray-1 dark:bg-contrast-dark shadow-md p-4 rounded-md">
      <BaseForm v-bind="form" @submit="handleSubmit" :loading="loading" />
    </div>
  </BaseSection>
</template>

<script>
import { mapActions } from "vuex";
import { getValue } from "@/utils/validators.js";

export default {
  name: "ContactForm",
  props: {
    role: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        elements: [
          {
            type: "BaseInput",
            props: {
              label: "Nom",
              value: getValue("name"),
              path: "name",
              required: true,
            },
          },
          {
            type: "BaseInput",
            props: {
              label: "Email",
              value: getValue("email"),
              path: "email",
              required: true,
              validator: "email",
            },
          },
          {
            type: "BaseSelect",
            props: {
              label: "Vous êtes",
              value: this.role || "",
              path: "role",
              options: [
                { label: "Utilisateur", value: "client" },
                { label: "Partenaire", value: "partner" },
                { label: "Autre", value: "other" },
              ],
              required: true,
              placeholder: "Sélectionnez ci-dessous",
            },
          },
          {
            type: "BaseTextArea",
            props: {
              label: "Message",
              value: getValue("message"),
              path: "message",
            },
          },
        ],
        submit: {
          type: "BaseButton",
          props: {
            text: "Envoyer",
            background: "transparent",
          },
        },
      },
      loading: false,
    };
  },
  methods: {
    async handleSubmit(e) {
      this.loading = true;
      try {
        await this.sendContact(e);
        // this.$toast.success("Message envoyé");
      } catch (err) {
        // this.$toast.error("Une erreur est survenue. Veuillez réessayer");
      }
      this.loading = false;
    },
    ...mapActions(["sendContact"]),
  },
  watch: {
    role() {
      this.form.elements[2].props.value = this.role || "";
    },
  },
};
</script>
