<template>
  <master-layout>
    <Home />
    <Features />
    <About />
    <Contact />
  </master-layout>
</template>

<script>
import Home from "@/components/Landing/Home";
import Features from "@/components/Landing/Features";
import About from "@/components/Landing/About";
import Contact from "@/components/Landing/Contact";

export default {
  name: "Landing",
  components: {
    Home,
    Features,
    About,
    Contact,
  },
};
</script>
