<template>
  <BaseSection id="contact" class="mb-16">
    <div
      class="flex justify-between flex-col md:flex-row w-full gap-4"
      v-scroll-reveal
    >
      <div class="flex flex-col my-auto">
        <BaseSubTitle>Discutons</BaseSubTitle>
        <BaseTitle>Contactez-nous </BaseTitle>
        <div class="mb-4 -mt-4">
          Pour toute question ou suggestion, écrivez-nous ! Si vous souhaitez
          collaborer, n'hésitez pas à entrer en contact avec nous.
        </div>
      </div>
      <div class="flex items-center">
        <BaseButton to="/contact" class="whitespace-nowrap">
          Envoyer un message
        </BaseButton>
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "Contact",
};
</script>
